import React, { useMemo, useState } from "react"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import NotFound from "decentraland-gatsby/dist/components/Layout/NotFound"
import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import { Button } from "decentraland-ui/dist/components/Button/Button"
import { Container } from "decentraland-ui/dist/components/Container/Container"
import { Header } from "decentraland-ui/dist/components/Header/Header"
import { Section } from "decentraland-ui/dist/components/Section/Section"
import Image from "semantic-ui-react/dist/commonjs/elements/Image/Image"

import PostCard from "../../../components/Blog/PostCard/PostCard"
import Search from "../../../components/Blog/Search/Search"
import BlogNavigation from "../../../components/Layout/BlogNavigation"
import Layout from "../../../components/Layout/Layout"
import {
  isWebpSupported,
  useImageOptimization,
} from "../../../hooks/contentful"
import { useBlogContentfulQuery } from "../../../hooks/useBlogContentfulQuery"
import {
  BlogPostAttributes,
  transformPostListToBlogPostAttributes,
} from "../../../modules/formats"

import "./index.css"

const POST_PER_PAGE = 6

export default function AuthorPage(props: any) {
  const l = useFormatMessage()
  const [fetchOptions, setFetchOptions] = useState({
    offset: 0,
    limit: POST_PER_PAGE,
  })
  const [hasLoadMore, setHasLoadMore] = useState(true)
  const [blogPosts, setBlogPosts] = useState<BlogPostAttributes[]>([])

  const { getBlogPostsByAuthor, getBlogAuthor } = useBlogContentfulQuery()

  const author = useMemo(() => {
    if (blogPosts.length > 0) {
      setFetchOptions({
        limit: POST_PER_PAGE,
        offset: 0,
      })
    }
    return getBlogAuthor(props.author)
  }, [props.author])

  const [, blogpostsState] = useAsyncMemo(
    async () => {
      if (!author) {
        return false
      }
      const { nodes: blogpostContent, pageInfo } = getBlogPostsByAuthor({
        authorId: props.author,
        ...fetchOptions,
      })
      const blogpostCurated =
        transformPostListToBlogPostAttributes(blogpostContent)
      setBlogPosts((prev) => {
        let newBlogPost: BlogPostAttributes[] = []
        if (fetchOptions.offset !== 0) {
          newBlogPost = [...prev]
        }
        newBlogPost = [...newBlogPost, ...blogpostCurated]

        setHasLoadMore(newBlogPost.length < pageInfo.total)
        return newBlogPost
      })
    },
    [author, fetchOptions],
    {
      callWithTruthyDeps: true,
    }
  )

  const image = useImageOptimization(author?.image.url)

  const loading = !blogpostsState.loaded || blogpostsState.loading

  return (
    <Layout
      {...props}
      isFullscreen
      isOverlay
      hideFooter={false}
      rightMenu={<Search />}
      className="layout__blog"
    >
      <Head
        title={author?.title || ""}
        description={author?.description || ""}
      />
      <BlogNavigation active={props.category} />

      <Container>
        {author && (
          <Section
            className={TokenList.join([
              "blogpost-author__header",
              !loading &&
                blogPosts &&
                blogPosts.length === 0 &&
                "blogpost-author__empty",
            ])}
          >
            <Image
              src={isWebpSupported() ? image.webp : image.jpg}
              size="small"
              circular
            />
            <Header>{author.title}</Header>
            <Header sub>{author.description}</Header>
          </Section>
        )}
        {!loading && blogPosts && blogPosts.length === 0 && (
          <div className="blog-post__empty-container">
            <NotFound
              title={l("page.blog.nothing_to_show")}
              description={l("page.blog.authors.empty_list")}
            />
          </div>
        )}
        {blogpostsState.loaded && blogPosts && blogPosts.length > 0 && (
          <div className="blogpost-author__wrapper">
            {blogPosts.map((post) => (
              <PostCard key={post.id} post={post} />
            ))}
          </div>
        )}
        {loading && (
          <div className="blogpost-author__wrapper">
            {Array.from(Array(5), (_, key) => (
              <PostCard key={key} loading />
            ))}
          </div>
        )}
        {hasLoadMore && (
          <div className="blog-post__load-more">
            <Button
              onClick={() => {
                setFetchOptions((prev) => ({
                  limit: POST_PER_PAGE,
                  offset: prev.offset + POST_PER_PAGE,
                }))
                setTimeout(
                  () =>
                    window.scrollBy({ top: 500, left: 0, behavior: "smooth" }),
                  0
                )
              }}
            >
              {l("page.blog.load_more")}
            </Button>
          </div>
        )}
      </Container>
    </Layout>
  )
}
